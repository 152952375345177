/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:7dc8c3ad-58a6-4509-9631-463e5ba54d74",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_o1Ennri7j",
    "aws_user_pools_web_client_id": "4cqq5ui2p0j048tos711npjpc9",
    "oauth": {}
};


export default awsmobile;
